/**
 * @ComponentFor MyReviewsPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import MyPagesBaseLayout from '../MyPagesBaseLayout';
import { pixelsToUnit, minMediumMediaQuery, minLargeMediaQuery, huge } from 'Shared/Style';
import { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import SearchBar from './components/SearchBar';
import ReviewList from './components/ReviewList';
import { translate, Breakpoint, currentUrl, replaceState, HistoryOptions } from '@avensia/scope';
import MyReviewsPageViewModelType from './MyReviewsPageViewModel.type';
import MyReviewViewModel from './MyReviewViewModel.type';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import Spinner from 'Shared/Icon/Spinner';
import H1 from 'Shared/Generic/h1';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import { Part } from 'Shared/PageLayout';

type ConnectStateType = {
  currentBreakpoint: number;
  isloading: boolean;
  myReviews: MyReviewViewModel[];
  siteName: string;
};

type PropType = MyReviewsPageViewModelType & ConnectStateType & ConnectPropType;

type StateType = {
  infinityScroll: boolean;
};

class MyReviewsPage extends React.Component<PropType, StateType> {
  currentlyReloading: boolean;
  unbindInfiniteScroll: () => void;
  MyReviewsList: HTMLDivElement;

  constructor(props: PropType) {
    super(props);

    this.state = {
      infinityScroll: false,
    };
    this.currentlyReloading = false;
  }

  componentWillUnmount() {
    this.deactivateInfinityScroll();
  }

  showMore = async () => {
    if (!this.currentlyReloading) {
      if (this.hasMore()) {
        this.currentlyReloading = true;
        const currentMyReviewsCount = this.props.myReviews.length;
        const newCount = this.props.pageSize;
        const newPageNumber = this.props.currentPage + 1;
        const skip = currentMyReviewsCount;
        const reload = async () => {
          try {
            await this.reloadPage(newPageNumber, newCount, skip, {
              hideSpinner: true,
              merge: (currentPage: MyReviewsPageViewModelType, newMyReviews: MyReviewsPageViewModelType) => {
                const array = [...currentPage.myReviews, ...newMyReviews.myReviews];
                const newPage = { ...currentPage, currentPage: newMyReviews.currentPage, myReviews: array };
                return newPage;
              },
            });
            this.currentlyReloading = false;
            return Promise.resolve();
          } catch (e) {
            this.deactivateInfinityScroll();
            this.currentlyReloading = false;
            return Promise.reject(null);
          }
        };

        this.props.feedback.push(reload());
      } else {
        this.deactivateInfinityScroll();
      }
    }
  };

  hasMore() {
    return this.props.totalCount > this.props.myReviews.length && this.props.currentPage < this.props.pageCount;
  }

  reloadPage(page: number, count: number, skip: number, loadOptions?: HistoryOptions) {
    const url = currentUrl();
    url.searchParams.set('page', String(page));
    url.searchParams.set('pageSize', String(count));
    url.hiddenParams.set('skip', String(skip));

    return replaceState(url, loadOptions);
  }

  deactivateInfinityScroll() {
    if (this.state.infinityScroll) {
      this.setState({ infinityScroll: false });
    }
    if (this.unbindInfiniteScroll) {
      this.unbindInfiniteScroll();
    }
  }

  activateInfiniteScroll = () => {
    this.showMore();
    this.setState({ infinityScroll: true });
    this.unbindInfiniteScroll = bindInfiniteScroll(this.MyReviewsList, this.showMore, -500);
  };

  render() {
    const FeedbackButton = this.props.feedback.Button;
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const myReviews = this.props.myReviews || [];
    const hideMobileBorder = (this.props.isLoading && isMobile) || (myReviews.length === 0 && isMobile);

    return (
      <MyPagesBaseLayout {...this.props}>
        <SearchBar isMobile={isMobile} />
        <HeaderWrapper css={hideMobileBorder && { borderBottom: { width: 0 } }}>
          {!isMobile && (
            <>
              <ProductTitle>{translate('/Account/MyPages/MyReviews/HeadTitle/ProductTitleAndPublish')}</ProductTitle>
              <Ratings>{translate('/Account/MyPages/MyReviews/HeadTitle/Ratings')}</Ratings>
              <MyReview>{translate('/Account/MyPages/MyReviews/HeadTitle/MyReview')}</MyReview>
              <AdminResponse>
                {`${this.props.siteName} ${translate('/Account/MyPages/MyReviews/HeadTitle/AdminRespond')}`}
              </AdminResponse>
            </>
          )}
        </HeaderWrapper>
        {!this.props.isLoading ? (
          myReviews.length > 0 ? (
            <ReviewList
              elementRef={el => (this.MyReviewsList = el)}
              isMobile={isMobile}
              productsReviews={myReviews}
              siteName={this.props.siteName}
            />
          ) : (
              <NoReviews>{translate('/Account/MyPages/MyReviews/NoReviewsAdded')}</NoReviews>
            )
        ) : (
            <LoadingSpinner />
          )}

        <Pagination>
          {this.hasMore() && (
            <FeedbackButton
              css={{
                padding: {
                  x: huge,
                },
              }}
              variant={ButtonVariant.Large}
              appearance={ButtonAppearance.Secondary}
              onClick={this.activateInfiniteScroll}
              disabled={this.state.infinityScroll}
            >
              {translate('/ProductListing/ShowMore')}
            </FeedbackButton>
          )}
        </Pagination>
      </MyPagesBaseLayout>
    );
  }
}

export default connectWithFeedback({ maximumFulfilled: 0 })(
  connect(state => ({
    currentBreakpoint: state.currentBreakpoint,
    isLoading: state.myReviewsSearch.isLoading,
    siteName: state.appShellData.siteName,
  }))(MyReviewsPage),
);

const DESKTOP_WIDTH = '25%';

const HeaderWrapper = styled.div({
  borderBottom: {
    color: theme => theme.borderColor,
    style: 'solid',
    width: pixelsToUnit(1),
  },
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: pixelsToUnit(46),
  paddingBottom: pixelsToUnit(11),
});

const LoadingSpinner = styled(Spinner, {
  display: 'flex',
  alignItems: 'center',

  margin: {
    x: 'auto',
    y: pixelsToUnit(30),
  },
  [minMediumMediaQuery]: {
    margin: {
      y: pixelsToUnit(50),
    },
    height: pixelsToUnit(35),
    width: pixelsToUnit(35),
  },
});

const Header = styled.div({
  fontWeight: 'bold',
});

const ProductTitle = styled(Header, {
  [minMediumMediaQuery]: {
    fontSize: pixelsToUnit(17),
    width: '35%',
  },
  [minLargeMediaQuery]: {
    width: DESKTOP_WIDTH,
  },
});

const Ratings = styled(Header, {
  [minMediumMediaQuery]: {
    fontSize: pixelsToUnit(17),
    width: '15%',
  },
  [minLargeMediaQuery]: {
    width: DESKTOP_WIDTH,
  },
});

const MyReview = styled(Header, {
  width: DESKTOP_WIDTH,
});

const NoReviews = styled(H1, {
  marginTop: pixelsToUnit(70),
  textAlign: 'center',
});

const AdminResponse = styled(Header, {
  width: DESKTOP_WIDTH,
});

const Pagination = styled(Part, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  order: 3,
  marginTop: huge,
});

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate } from '@avensia/scope';
import { pixelsToUnit } from 'Shared/Style';
import { transition } from 'Shared/Style';
import { Up, Down } from 'Shared/Icon/Arrow';

type PropType = {
  buttonLabel?: string;
  buttonAlignment?: 'left' | 'center' | 'right';
  height?: number;
  includeArrow?: boolean;
  text: string;
} & StyledProps;

type StateType = {
  isExpanded: boolean;
  textWrapperHeight: number | string;
  showGradientEffect: boolean;
  isLoading: boolean;
};

export default styled(
  class ReadMore extends React.Component<PropType, StateType> {
    textWrapperRef: HTMLDivElement;

    constructor(props: PropType) {
      super(props);

      this.state = {
        isLoading: false,
        showGradientEffect: false,
        isExpanded: false,
        textWrapperHeight: this.props.height,
      };
    }

    componentDidMount() {
      const divHeight = this.textWrapperRef.clientHeight;

      if (divHeight > this.props.height) {
        this.setState({ showGradientEffect: true, textWrapperHeight: this.props.height });
      }

      this.setState({ isLoading: true });
    }

    onReadMyReviewClick = () => {
      const { isExpanded } = this.state;

      this.setState({ isExpanded: !isExpanded });

      if (!isExpanded) {
        this.setState({ textWrapperHeight: this.textWrapperRef.clientHeight });
      } else {
        this.setState({
          textWrapperHeight: this.props.height,
        });
      }
    };

    renderArrowIcon = () => {
      return <IconContent>{this.state.isExpanded ? <ArrowUp /> : <ArrowDown />}</IconContent>;
    };

    render() {
      const { buttonAlignment = 'left', includeArrow, text, compose, buttonLabel } = this.props;
      const readMoreLabel = !!buttonLabel ? buttonLabel : translate('/Shared/ReadMore');

      return (
        <styled.Div css={compose()}>
          <TextWrapper css={{ height: this.state.textWrapperHeight }}>
            <styled.Div
              // tslint:disable-next-line:jsx-no-lambda
              ref={(el: HTMLDivElement) => (this.textWrapperRef = el)}
            >
              {text}
            </styled.Div>
            {!this.state.isExpanded && this.state.showGradientEffect && <Gradient />}
          </TextWrapper>
          {this.state.showGradientEffect && (
            <ReadMoreButton
              css={{
                display: includeArrow ? 'flex' : 'block',
                textAlign: buttonAlignment,
              }}
              onClick={this.onReadMyReviewClick}
            >
              {this.state.isExpanded ? (
                <span>{translate('/Account/MyPages/MyReviews/ReadLess')}</span>
              ) : (
                <span>{readMoreLabel}</span>
              )}

              {includeArrow && this.renderArrowIcon()}
            </ReadMoreButton>
          )}
        </styled.Div>
      );
    }
  },
);

const IconContent = styled.div({
  marginLeft: pixelsToUnit(3),
  paddingTop: pixelsToUnit(5),
  paddingLeft: pixelsToUnit(5),
});

const ArrowStyled = styled({
  fontSize: pixelsToUnit(16),
});

const ArrowUp = ArrowStyled(Up);
const ArrowDown = ArrowStyled(Down);

const TextWrapper = styled.div({
  overflow: 'hidden',
  position: 'relative',
  ...transition({ property: 'height' }),
});

const Gradient = styled.div({
  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9))',
  bottom: 0,
  height: '70%',
  position: 'absolute',
  width: '100%',
});

const ReadMoreButton = styled.div({
  cursor: 'pointer',
  marginTop: pixelsToUnit(21),
  textAlign: 'center',
  textDecoration: 'underline',
});

import React from 'react';
import ReviewItem from './ReviewItem';
import MyReviewViewModel from './../MyReviewViewModel.type';

type PropTypes = {
  isMobile: boolean;
  productsReviews: MyReviewViewModel[];
  siteName: string;
  elementRef?: React.Ref<HTMLDivElement>;
};

export default (props: PropTypes) => (
  <div ref={props.elementRef}>
    {props.productsReviews.map((item: MyReviewViewModel, index: number) => (
      <ReviewItem key={index + Date.now()} reviewItem={item} isMobile={props.isMobile} siteName={props.siteName} />
    ))}
  </div>
);

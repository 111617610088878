import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate } from '@avensia/scope';
import { pixelsToUnit } from 'Shared/Style/unit';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import AverageRating, { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import { thin, alto, minTinyMediaQuery, minSmallMediaQuery, minLargeMediaQuery } from 'Shared/Style';
import MyReviewViewModel from './../MyReviewViewModel.type';
import Link from 'Shared/Link';
import ReadMore from 'Shared/ReadMore';

type PropType = {
  reviewItem: MyReviewViewModel;
  isMobile: boolean;
  siteName: string;
} & StyledProps;

const FIXEDHEIGHT = 96;

const ReviewItem = (props: PropType) => {
  const { productUrl, productImageUrl, createdDate } = props.reviewItem;
  const timeStamp = new Date(createdDate);
  const day = ('0' + timeStamp.getDate()).slice(-2);
  const month = ('0' + (timeStamp.getMonth() + 1)).slice(-2);
  const year = timeStamp.getFullYear();
  const created = `${year}-${month}-${day}`;

  return (
    <ItemWrapper>
      <ProductContent>
        {props.isMobile && (
          <Header css={{ width: '100%' }}>
            {translate('/Account/MyPages/MyReviews/HeadTitle/ProductTitleAndPublish')}
          </Header>
        )}
        <ImageAndProductInfoWrapper>
          <ImageInfo>
            <ContentLink to={productUrl}>
              <ImageStyled
                src={productImageUrl}
                preset={!productImageUrl.includes('.gif') ? ImagePreset.Tiny : undefined}
                ratio={ImageRatio.OneToOne}
              />
            </ContentLink>
          </ImageInfo>
          <ProductInfo>
            <ContentLink to={productUrl}>
              <ProductTitle>{props.reviewItem.productTitle}</ProductTitle>
            </ContentLink>
            <ProductReviewDateCreated>{created}</ProductReviewDateCreated>
          </ProductInfo>
        </ImageAndProductInfoWrapper>
      </ProductContent>

      <RatingContent
        css={
          props.isMobile && {
            height: pixelsToUnit(55),
            overflow: 'hidden',
            position: 'relative',
          }
        }
      >
        {props.isMobile && <Header>{translate('/Account/MyPages/MyReviews/HeadTitle/Ratings')}</Header>}
        <Ratings average={props.reviewItem.rating} spacing={Spacing.Normal} size={Size.Normal} />
      </RatingContent>

      <MyReviewContent>
        {props.isMobile && <Header>{translate('/Account/MyPages/MyReviews/HeadTitle/MyReview')}</Header>}
        <ReadMore
          height={FIXEDHEIGHT}
          text={props.reviewItem.reviewText}
          buttonLabel={translate('/Account/MyPages/MyReviews/ReadFullReview')}
          buttonAlignment="left"
          includeArrow
        />
      </MyReviewContent>

      <AdminResponseContent>
        {props.isMobile && (
          <Header>{`${props.siteName} ${translate('/Account/MyPages/MyReviews/HeadTitle/AdminRespond')}`}</Header>
        )}

        <ReadMore
          height={FIXEDHEIGHT}
          text={props.reviewItem.reviewAdminAnswer}
          buttonLabel={translate('/Account/MyPages/MyReviews/ReadFullAnswer')}
          buttonAlignment="left"
          includeArrow
        />
      </AdminResponseContent>
    </ItemWrapper>
  );
};

export default styled(ReviewItem);

const DESKTOP_WIDTH = '25%';

const ItemWrapper = styled.div({
  borderBottom: {
    style: 'solid',
    width: thin,
    color: alto,
  },
  display: 'flex',
  flexFlow: 'row wrap',
  padding: {
    y: pixelsToUnit(20),
  },
});

const ImageAndProductInfoWrapper = styled.div({
  display: 'flex',
});

const ImageStyled = styled(Image, {
  width: pixelsToUnit(84),
  height: 'auto',
});

const infoStyle = {
  marginRight: pixelsToUnit(20),
  alignContent: 'flex-start',
};

const ImageInfo = styled.div(infoStyle);

const ProductInfo = styled.div({
  ...infoStyle,
  width: '100%',
});

const ContentLink = styled(Link, {
  color: theme => theme.textColor,
  display: 'flex',
  flexFlow: 'column nowrap',
  flexBasis: pixelsToUnit(84),
});

const ProductTitle = styled.div({
  minHeight: pixelsToUnit(36),
});

const ProductReviewDateCreated = styled.div({
  height: pixelsToUnit(36),
  paddingTop: pixelsToUnit(15),
});

const ProductContent = styled.div({
  width: '100%',
  [minTinyMediaQuery]: {
    width: '50%',
  },
  [minSmallMediaQuery]: {
    width: '35%',
  },
  [minLargeMediaQuery]: {
    width: DESKTOP_WIDTH,
  },
});

const RatingContent = styled.div({
  width: '100%',
  [minTinyMediaQuery]: {
    width: '50%',
  },
  [minSmallMediaQuery]: {
    width: '15%',
  },
  [minLargeMediaQuery]: {
    width: DESKTOP_WIDTH,
  },
});

const MyReviewContent = styled.div({
  marginTop: pixelsToUnit(20),
  paddingRight: pixelsToUnit(20),
  width: '100%',
  [minTinyMediaQuery]: {
    width: '50%',
  },
  [minSmallMediaQuery]: {
    marginTop: 0,
    width: '25%',
  },
});

const AdminResponseContent = styled.div({
  marginTop: pixelsToUnit(20),
  paddingRight: pixelsToUnit(20),
  width: '100%',
  [minTinyMediaQuery]: {
    width: '50%',
  },
  [minSmallMediaQuery]: {
    marginTop: 0,
    width: '25%',
  },
});

const Ratings = styled(AverageRating, {
  height: pixelsToUnit(20),
  position: 'absolute',
  width: '100%',
  zIndex: 1,
});

const Header = styled.div({
  fontWeight: 'bold',
  fontSize: pixelsToUnit(16),
  paddingBottom: pixelsToUnit(5),
});

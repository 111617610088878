import React from 'react';
import connect from 'Shared/connect';
import { styled, StyledProps } from '@glitz/react';
import { changeSearchText, searchRequestStatus } from 'Account/MyPages/MyReviews/action-creators';
import { translate, postJson, addUserLog, currentUrl, updateCurrentPage } from '@avensia/scope';
import { large, sigma, pixelsToUnit, thin } from 'Shared/Style';
import Icon from 'Shared/Icon/Search';
import MyReviewsPageViewModelType from 'Account/MyPages/MyReviews/MyReviewsPageViewModel.type';

type PropType = {
  isMobile: boolean;
  searchText: string;
} & StyledProps &
  ConnectActionType;

type ConnectActionType = {
  updateCurrentPage: (updater: (page: MyReviewsPageViewModelType) => MyReviewsPageViewModelType) => void;
  onChangeSearchText: (searchText: string) => Promise<void>;
  onSearchRequest: (isLoading: boolean) => Promise<void>;
};

const SEARCH_URL = '/search';
const SEARCH_FAILED = 'My reviews Search failed';
class SearchBar extends React.Component<PropType, {}> {
  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const query = { searchKeyword: this.props.searchText };
    const myReviewsSearchUrl = currentUrl().pathname + SEARCH_URL;
    try {
      this.props.onSearchRequest(true);
      const result = await postJson(myReviewsSearchUrl, query);
      if (!!result) {
        this.props.onSearchRequest(false);
      }
      this.props.updateCurrentPage((current) => ({ ...current, myReviews: result }));
    } catch (error) {
      addUserLog(SEARCH_FAILED);
    }
  };

  onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    return this.props.onChangeSearchText(e.target.value);
  };

  render() {
    return (
      <SearchForm css={this.props.compose()} onSubmit={this.onSubmit}>
        <Field css={this.props.isMobile && { margin: { x: 'auto' } }}>
          <Container>
            <SearchInput
              type="search"
              value={this.props.searchText}
              placeholder={translate('/Account/MyPages/MyReviews/QuickSearch/Placeholder')}
              onChange={this.onChange}
            />
          </Container>
          <Button
            css={{
              backgroundColor: (theme) => theme.buttonSearchHeaderBackgroundColor,
              padding: {
                x: large,
              },
            }}
            type="submit"
          >
            <SearchIcon />
          </Button>
        </Field>
      </SearchForm>
    );
  }
}

export default styled(
  connect(
    (state) => ({
      searchText: state.myReviewsSearch.searchText,
    }),
    (dispatch) => ({
      updateCurrentPage(updater: (page: MyReviewsPageViewModelType) => MyReviewsPageViewModelType) {
        dispatch(updateCurrentPage(updater));
      },
      onChangeSearchText(searchText: string) {
        dispatch(changeSearchText(searchText));
      },
      onSearchRequest(isLoading: boolean) {
        dispatch(searchRequestStatus(isLoading));
      },
    }),
  )(SearchBar),
);

const SearchForm = styled.form({
  position: 'relative',
});

const SearchInput = styled.input({
  backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColor,
  border: {
    xy: {
      style: 'none',
    },
  },
  color: (theme) => theme.inputTextColor,
  flexGrow: 1,
  fontSize: sigma,
  lineHeight: 'normal',
  padding: {
    top: pixelsToUnit(14),
    bottom: pixelsToUnit(12),
    x: pixelsToUnit(17),
  },
});

const Container = styled.div({
  flexGrow: 1,
  display: 'flex',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: (theme) => theme.inputSearchHeaderBorderColor,
    },
    right: {
      width: 0,
    },
  },
});

const Field = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  maxWidth: pixelsToUnit(400),
  margin: {
    y: pixelsToUnit(22),
  },
});

const Button = styled.button({
  border: {
    xy: {
      style: 'none',
    },
  },
  cursor: 'pointer',
});

const SearchIcon = styled(Icon, {
  color: (theme) => theme.menuBarTextColor,
});
